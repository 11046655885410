import React from "react"
import AboutSection from "./AboutSection"
import "../App.css";


function About() {
    const style = {
        backgroundColor: "#F7F7F7"
    }
    return(
        <div style={style}>
            <AboutSection /> 
        </div>
    )
};
export default About